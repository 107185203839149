import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Projects",
  "date": "2019-02-07",
  "author": "Achilleas",
  "path": "/abouttext"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><inlineCode parentName="p">{`> whois:`}</inlineCode>{` `}</p>
    <pre><code parentName="pre" {...{}}>{`> Hello I'm Achilleas (Achilles)
`}</code></pre>
    <p><inlineCode parentName="p">{`> academic`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`>   Computer Science & Mathematics (St Andrews BSc)
>   Computational Statistics and Machine Learning (UCL MSc)
`}</code></pre>
    <p><inlineCode parentName="p">{`> industry`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`> I'm currently working in the aquaculture industry as a Data Scientist.
`}</code></pre>
    <p><inlineCode parentName="p">{`> interests:`}</inlineCode>{` `}</p>
    <pre><code parentName="pre" {...{}}>{`> tennis, snowboarding, climbing, hiking, running
> reading, board games, role playing games
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      